<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h2>Agendamento de paciente</h2>

        <hr />
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="agendarPaciente">
            <div v-if="$route.params.flag == 1" class="row">
              <div class="col-md-12 mt-4">
                <h5>Paciente (Obrigatório)</h5>
                <v-text-field
                  disabled
                  :value="$route.params.nomePaciente"
                ></v-text-field>
              </div>
            </div>

            <div v-if="$route.params.flag == 0" class="row">
              <div class="col-md-12">
                <h5>Paciente (Obrigatório)</h5>
                <validation-provider
                  v-slot="{ errors }"
                  name="Paciente"
                  rules="required"
                >
                  <vue-bootstrap-typeahead
                    :value="value"
                    :data="listPacientes"
                    @hit="value = $event"
                    size="lg"
                    :serializer="s => s.text"
                    placeholder="Selecione o paciente"
                    backgroundVariant="primary text-white"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
                <p v-if="messageError">
                  Paciente não encontrado, selecione/digite corretamente o nome
                </p>
              </div>
            </div>
            <div class="row mt-6">
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Data do agendamento"
                  rules="required"
                >
                  <v-text-field
                    type="date"
                    v-model="dataAgendamento"
                    label="Data do agendamento (Obrigatório)"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Horário Inicial (Obrigatório)"
                  rules="required"
                >
                  <v-text-field
                    type="time"
                    :error-messages="errors"
                    v-model="horarioInicio"
                    label="Horário Inicial (Obrigatório)"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row mt-6">
              <div class="col-md-6">
                <h6 style="color: grey">Tipo do agendamento (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Tipo do agendamento"
                  rules="required"
                >
                  <b-form-select
                    v-model="tipo"
                    :error-messages="errors"
                    :options="options"
                  ></b-form-select>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <h6 style="color: grey">Especialidade (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Especialidade"
                  rules="required"
                >
                  <b-form-select
                    v-model="especialidade"
                    :error-messages="errors"
                    :options="options2"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <v-btn
              :disabled="invalid"
              @click="agendarPaciente"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Agendar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";
import { required } from "vee-validate/dist/rules";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import Vue from "vue";

// Global registration
Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});

export default {
  name: "cadastrarContaUsuario",
  components: {
    ValidationProvider,
    ValidationObserver
  },

  async created() {
    try {
      const response = await ambulatorio.listPacientes();
      var aux = response.data.pacientes;
      aux.map(paciente => {
        this.listPacientes = [
          ...this.listPacientes,
          { id: paciente.id, text: paciente.nome }
        ];
      });
      console.log(this.listPacientes);
    } catch (error) {
      console.log(error);
    }
  },

  data() {
    return {
      messageError: false,
      value:
        this.$route.params.idPaciente == -1
          ? "aaaaaa"
          : this.$route.params.nomePaciente,
      listPacientes: [],
      dataAgendamento: "",
      horarioInicio: "",
      horarioFinal: "",
      tipo: null,
      especialidade: null,
      pacienteId: "",
      options: [
        { value: null, text: "Selecione um tipo" },
        { value: "Retorno", text: "Retorno" },
        { value: "Consulta", text: "Consulta" }
      ],
      options2: [
        { value: null, text: "Selecione uma especialidade" },
        { value: 1, text: "Medicina de família e comunidade" },
        { value: 2, text: "Neurologia" },
        { value: 3, text: "Cardiologia" },
        { value: 4, text: "Endocrinologia" },
        { value: 5, text: "Pediatria" },
        { value: 6, text: "Ginecologia" },
        { value: 7, text: "Obstetrícia" },
        { value: 8, text: "Reumatologia" },
        { value: 9, text: "Dermatologia" },
        { value: 10, text: "Nefrologia" },
        { value: 11, text: "Urologia" },
        { value: 12, text: "Otorrinolaringologia" },
        { value: 13, text: "Ortopedia" },
        { value: 14, text: "Pequenas cirurgias" }
      ]
    };
  },

  methods: {
    agendarPaciente: async function() {
      try {
        this.pacienteId =
          this.$route.params.idPaciente == -1
            ? this.value.id
            : this.$route.params.idPaciente;

        this.$refs.observer.validate();

        var data = {
          agendamento: {
            data: this.dataAgendamento,
            horario_inicio: this.horarioInicio,
            horario_final: "",
            status: null,
            tipo: this.tipo,
            especialidade_id: this.especialidade,
            paciente_id: this.pacienteId
          }
        };

        this.messageError = false;
        await ambulatorio.registerAgendamento(data);

        Swal.fire({
          title: "",
          text: "Agendamento realizado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
        setTimeout(() => {
          this.$router.push({ name: "listaAgendamentos" });
        }, 1000);
      } catch (error) {
        this.messageError = true;
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar agendamento, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Novo agendamento" }]);
  }
};
</script>

<style>
.theme--light.v-messages {
  color: red !important ;
}
</style>
