import axios from "axios";

const client = axios.create({
  baseURL: "https://api.softmedical.com.br/"
});

client.interceptors.request.use(async config => {
  config.headers.Authorization = window.localStorage.getItem("ambulatorio");

  return config;
});

export function getCidades() {
  return axios.get(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados/RN/municipios"
  );
}

export function loginUser(email, password) {
  const data = {
    sessions: {
      login: email,
      password: password
    }
  };

  return client.post("usuarios/sign_in", data);
}

export function registerUser(data) {
  return client.post("usuarios", data);
}

export function registerUserRecepcionista(data) {
  return client.post("recepcionistas", data);
}

export function registerUserMedicos(data) {
  return client.post("medicos", data);
}

export function registerUserEnfermeiros(data) {
  return client.post("enfermeiros", data);
}

export function registerUserAluno(data) {
  return client.post("alunos", data);
}

export function registerPaciente(data) {
  return client.post("pacientes", data);
}

export function atualizarPaciente(data, id) {
  return client.put(`pacientes/${id}`, data);
}

export function listPacientes() {
  return client.get("pacientes");
}

export function listPacientesPaged(page) {
  return client.get(`pacientes?page=${page}&size=10`);
}

export function buscaPacientes(data) {
  return client.post("pacientes/busca", data);
}

export function listUsuarios() {
  return client.get("usuarios");
}

export function updateUsuario(id, data) {
  return client.put(`usuarios/${id}`, data);
}

export function indexPaciente(id) {
  return client.get(`pacientes/${id}`);
}

export function indexUsuario(id) {
  return client.get(`usuarios/${id}`);
}

export function listTriagens() {
  return client.get("triagens");
}

export function listUsuariosPaged(page) {
  return client.get(`usuarios?page=${page}&size=10`);
}

export function buscaUsuarios(data) {
  return client.post("usuarios/busca", data);
}

export function listTriagensPaged(page) {
  return client.get(`triagens?page=${page}&size=10`);
}

export function buscaTriagens(data) {
  return client.post("triagens/busca", data);
}

export function registerTriagem(data) {
  return client.post("triagens", data);
}

export function indexTriagem(id) {
  return client.get(`triagens/${id}`);
}

export function triagemPorProntuário(id) {
  return client.get(`triagens/${id}/prontuario`);
}

export function updateTriagem(id, data) {
  return client.put(`triagens/${id}`, data);
}

export function registerAgendamento(data) {
  return client.post("agendamentos", data);
}

export function listAgendamentos() {
  return client.get("agendamentos");
}

export function buscaAgendamentos(data) {
  return client.post("agendamentos/busca", data);
}

export function listAgendamentosPaged(page) {
  return client.get(`agendamentos?page=${page}&size=10`);
}

export function indexAgendamento(id) {
  return client.get(`agendamentos/${id}`);
}

export function listEncaminhamentosHoje() {
  return client.get("agendamentos/hoje");
}

export function deleteEncaminhamento(id) {
  return client.delete(`encaminhamentos/${id}`);
}

export function updateAgendamento(id, data) {
  return client.put(`agendamentos/${id}`, data);
}

export function encaminharAgendamento(data) {
  return client.post("encaminhamentos", data);
}

export function encaminharPaciente(data) {
  return client.post("encaminhamentos", data);
}

export function listEncaminhamentos() {
  return client.get("encaminhamentos");
}

export function listEncaminhamentosPaged(page, setor) {
  return client.get(`encaminhamentos/${setor}/setor?page=${page}&size=10`);
}

export function buscaHistoricoConsulta(data) {
  return client.post("historicos_consultas/busca", data);
}

export function prontuarioConsultaShowTriagem(id) {
  return client.get(`historicos_consultas/${id}`);
}

export function listConsultasTemporarias(page) {
  return client.get(`temp_historicos_consultas?page=${page}&size=10`);
}

export function listHistoricoConsulta(idProntuario, page) {
  return client.get(
    `historicos_consultas/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function finalizarEncaminhamentoConsulta(idEncaminhamento) {
  return client.get(`encaminhamentos/${idEncaminhamento}/finalizar`);
}

export function finalizarEncaminhamento(idEncaminhamento) {
  return client.get(`temp_historicos_consultas/${idEncaminhamento}/finalizar`);
}

export function indexEncaminhamento(id) {
  return client.get(`encaminhamentos/${id}`);
}

export function buscaEncaminhamentos(data) {
  return client.post("encaminhamentos/busca", data);
}

export function buscaEncaminhamentosTemporarios(data) {
  return client.post("temp_historicos_consultas/busca", data);
}

export function getPreNatalByProntuario(idProntuario) {
  return client.get(`pre_natais/prontuario/${idProntuario}?page=1`);
}

export function createPreNatal(data) {
  return client.post("pre_natais", data);
}

export function updatePreNatal(id, data) {
  return client.put(`pre_natais/${id}`, data);
}

export function historicoPreNatal(idProntuario, page) {
  return client.get(
    `historicos_pre_natais/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function createGestacaoAtual(data) {
  return client.post("gestacoes_atual", data);
}

export function updateGestacaoAtual(data, idGestacao) {
  return client.put(`gestacoes_atual/${idGestacao}`, data);
}

export function showGestacaoAtual(idGestacao) {
  return client.get(`gestacoes_atual/${idGestacao}`);
}

export function getGestacaoAtualProntuario(idPreNatal, page) {
  return client.get(
    `gestacoes_atual/pre_natal/${idPreNatal}?page=${page}&size=10`
  );
}

export function historicoNascimentos(idProntuario, page) {
  return client.get(
    `historicos_nascimentos/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function HistoricoTriagens(idProntuario, page) {
  return client.get(
    `historicos_triagens/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function createTestesNascimentos(data) {
  return client.post("testes_nascimentos", data);
}

export function updateTestesNascimentos(id, data) {
  return client.put(`testes_nascimentos/${id}`, data);
}

export function getTestesNascimentos(idProntuario) {
  return client.get(`testes_nascimentos/${idProntuario}/prontuario`);
}

export function createNascimento(data) {
  return client.post("nascimentos", data);
}

export function updateNascimento(id, data) {
  return client.put(`nascimentos/${id}`, data);
}

export function getByProntuarioNascimento(idProntuario) {
  return client.get(`nascimentos/${idProntuario}/prontuario`);
}

export function createProcedimento(data) {
  return client.post("procedimentos", data);
}

export function addTipoProcedimento(data) {
  return client.post("tipos_procedimento", data);
}

export function updateProcedimentos(id, data) {
  return client.put(`procedimentos/${id}`, data);
}

export function getByProntuarioProcedimentos(idProntuario) {
  return client.get(`procedimentos/${idProntuario}/prontuario`);
}

export function historicoProcedimentos(idProntuario, page) {
  return client.get(
    `historicos_procedimentos/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function loadTiposProcedimentos() {
  return client.get("tipos_procedimento");
}

export function getExamePorTipo(tipo) {
  return client.get(`exames?tipo=${tipo}`);
}

export function listExamesResultadoPaged(idProntuario, page) {
  return client.get(
    `exames_resultados/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function adicionarExame(data) {
  return client.post("exames", data);
}

export function solicitarExames(data) {
  return client.post("solicitacoes_exames", data);
}

export function updateResultadoExames(id, data) {
  return client.put(`exames_resultados/${id}`, data);
}

export function buscaResultadoExames(data) {
  return client.post("exames_resultados/busca", data);
}

export function listGestacionalPaged(idProntuario, page) {
  return client.get(
    `ultrassonografias_gestacionais/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function cadastrarGestacional(data) {
  return client.post("ultrassonografias_gestacionais", data);
}

export function showGestacionalAtual(idGestacional) {
  return client.get(`ultrassonografias_gestacionais/${idGestacional}`);
}

export function atualizarGestacional(data, idGestacional) {
  return client.put(`ultrassonografias_gestacionais/${idGestacional}`, data);
}

export function buscaResultadoNascimento(data) {
  return client.post("historicos_nascimentos/busca", data);
}

export function buscaResultadoTriagem(data) {
  return client.post("historicos_triagens/busca", data);
}

export function buscaResultadoPreNatal(data) {
  return client.post("historicos_pre_natais/busca", data);
}

export function buscaResultadoProcedimentos(data) {
  return client.post("historicos_procedimentos/busca", data);
}

export function listDeTiposVacinas() {
  return client.get(`vacinas`);
}

export function updateVacina(id, data) {
  return client.put(`vacinas_paciente/${id}`, data);
}

export function showVacina(id) {
  return client.get(`vacinas_paciente/${id}`);
}

export function listVacinasPaciente(idPaciente, tipo) {
  return client.get(`vacinas_paciente/${idPaciente}/prontuario?tipo=${tipo}`);
}

export function registerVacinaPaciente(data) {
  return client.post("vacinas_paciente", data);
}

export function adicionarNovaVacina(data) {
  return client.post("vacinas", data);
}

export function registerHistoriaPaciente(data) {
  return client.post("historias_pacientes", data);
}

export function listaHistoriaPaciente(idProntuario, page) {
  return client.get(
    `historicos_historias_pacientes/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function showProntuarioHistoriaPaciente(idProntuario) {
  return client.get(`historias_pacientes/${idProntuario}/prontuario`);
}

export function atualizarHistoriaPaciente(id, data) {
  return client.put(`historias_pacientes/${id}`, data);
}

// familiar
export function regiterHistoricoFamiliar(data) {
  return client.post("historias_familia", data);
}

export function atualizarHistoricoFamiliar(id, data) {
  return client.put(`historias_familia/${id}`, data);
}

export function listaHistoricoFamiliar(idProntuario, page) {
  return client.get(
    `historicos_hist_familia/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function showProntuarioHistoriaFamiliar(idProntuario) {
  return client.get(`historias_familia/${idProntuario}/prontuario`);
}

// patológica

export function regiterHistoricoPatologico(data) {
  return client.post("historias_patologicas", data);
}

export function atualizarHistoricoPatologico(id, data) {
  return client.put(`historias_patologicas/${id}`, data);
}

export function listaHistoricoPatologico(idProntuario, page) {
  return client.get(
    `historicos_hist_patologicas/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function showProntuarioHistoriaPatologico(idProntuario) {
  return client.get(`historias_patologicas/${idProntuario}/prontuario`);
}

// hábitos de vida

// patológica

export function regiterHistoricoHabitos(data) {
  return client.post("habitos", data);
}

export function atualizarHistoricoHabitos(id, data) {
  return client.put(`habitos/${id}`, data);
}

export function listaHistoricoHabitos(idProntuario, page) {
  return client.get(
    `historicos_habito/${idProntuario}/prontuario?page=${page}&size=10`
  );
}

export function showProntuarioHistoriaHabitos(idProntuario) {
  return client.get(`habitos/${idProntuario}/prontuario`);
}